import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {textAppear} from "../../../shared/transition";

export const magicItemShow = (items) => {
    items.forEach((item) => {
        ScrollTrigger.create({
            animation: tlItemShow(item),
            trigger: item,
            start: 'top bottom'
        });
    });
}

export const tlItemShow = (item) => {
    const tl = gsap.timeline();

    tl.add(textAppear(item), 0);

    return tl;
}

export const magicShow = (trigger, tweens) => {
    ScrollTrigger.create({
        animation: tlShow(tweens),
        trigger,
        start: 'top bottom'
    });
}

export const tlShow = (tweens) => {
    const tl = gsap.timeline();

    tl.add(textAppear(tweens), 0);

    return tl;
}
