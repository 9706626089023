import {createRef, useContext, useEffect, useRef} from "react";
import {ButtonCta} from "../../components/Button";
import {LayoutContext, LocalesContext} from "../../context";
import {tlEnter} from "./transitions";
import VideoEmbedded from "../../components/VideoEmbedded";

export const HeroSection = () => {
    const {data} = useContext(LocalesContext);
    const {hero} = data;
    const {isLoading} = useContext(LayoutContext);
    const headerRef = useRef();
    const textRef = useRef();
    const actionRef = createRef();

    useEffect(() => {
        if(!isLoading) {
            tlEnter([headerRef.current, textRef.current, actionRef.current]).play();
        }
    }, [isLoading, actionRef]);

    return (
        <header className="sw-hero">
            <div className="sw-hero-content -cb">
                <div className="sw-hero-container">
                    <div className="sw-hero-main">
                        <div className="sw-hero-video">
                            <VideoEmbedded
                                src={"/video/1.mp4"}
                                srcMobile={"/video/1_1.mp4"}
                            />
                        </div>
                        <div className="sw-hero-body">
                            <div className="sw-hero-header">
                                <h1 ref={headerRef}>{hero.header}</h1>
                            </div>
                            <div className="sw-hero-text">
                                <p ref={textRef}>{hero.text}</p>
                            </div>
                            <div className="sw-hero-action">
                                <ButtonCta
                                    text="Trade now"
                                    link="https://app.swych.finance"
                                    ref={actionRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
