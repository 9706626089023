import {useContext, useEffect, useRef} from "react";
import {LocalesContext} from "../../context";
import {ButtonCta} from "../../components/Button";
import {magicShow} from "./transitions";
import VideoEmbedded from "../../components/VideoEmbedded";

export const OfferSection = () => {
    const {data} = useContext(LocalesContext);
    const {offer} = data;
    const triggerRef = useRef();
    const captionRef = useRef();
    const headerRef = useRef();
    const actionRef = useRef();

    useEffect(() => {
        magicShow(
            triggerRef.current,
            [captionRef.current, headerRef.current, actionRef.current]
        );
    }, []);

    return (
        <section className="sw-offer">
            <div className="sw-offer-content">
                <div className="sw-offer-container">
                    <div className="sw-offer-main">
                        <div className="sw-offer-video">
                            <VideoEmbedded
                                src={"/video/2.mp4"}
                                srcMobile={"/video/2_1.mp4"}
                            />
                        </div>
                       <div className="sw-offer-body" ref={triggerRef}>
                           <div className="sw-offer-caption">
                               <span ref={captionRef}>
                                   {offer.caption}
                               </span>
                           </div>
                           <div className="sw-offer-header">
                               <h2 ref={headerRef}>
                                   {offer.header}
                               </h2>
                           </div>
                           <div className="sw-offer-action">
                               <ButtonCta
                                   text="Learn more"
                                   ref={actionRef}
                               />
                           </div>
                       </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
