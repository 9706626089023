import {HeroSection} from "./hero-section";
import {AdvantageSection} from "./advantage-section";
import {FeatureSection} from "./feature-section";
import {BrandSection} from "./brand-section";
import {OfferSection} from "./offer-section";

const Sections = () => {
    return (
        <>
            <HeroSection/>
            <AdvantageSection/>
            <FeatureSection/>
            <BrandSection/>
            <OfferSection/>
        </>
    )
}

export default Sections;
