import {useContext, useRef, useEffect, useState} from "react";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {LayoutContext, LocalesContext} from "../../context";
import {
    Stat,
    ArrowRightUpT,
    People,
    Chart
} from "../../components/Icon";
import {magicShow, tlShow} from "./transitions";
import { GraphQLClient } from 'graphql-request'
import {ethers} from 'ethers';

export const AdvantageSection = () => {
    const {data} = useContext(LocalesContext);
    const {isLoading} = useContext(LayoutContext);
    const {advantage} = data;
    const icons = [People];
    const triggerRef = useRef();
    const itemRef = useRef([]);
    itemRef.current = [];
    const [volume, setVolume] = useState(0);
    const [liquidity, setLiquidity] = useState(0);
    const [totalVolume, setTotalVolume] = useState(0);
    const [volume24, setVolume24] = useState(0);
    const [currentBlock, setCurrentBlock] = useState(0);
    
    useEffect(() => {
        const infoClient = new GraphQLClient('https://api.thegraph.com/subgraphs/name/swychfinance/exchange')
        const query = `query overviewCharts($startTime: Int!, $skip: Int!) {
                pancakeDayDatas(first: 1000, skip: $skip, where: { date_gt: $startTime }, orderBy: date, orderDirection: asc) {
                    date
                    dailyVolumeUSD
                    totalLiquidityUSD
                }
            }`;
        const variables = {
            startTime: Math.floor(Date.now() / 1000) - 86400,
            skip: 0
        };
        infoClient.request(query, variables).then(data => {
            setLiquidity(data.pancakeDayDatas[0].totalLiquidityUSD);
        })
    }, []);

    useEffect(() => {
        const infoClient = new GraphQLClient('https://api.thegraph.com/subgraphs/name/swychfinance/exchange')
        const query = `query overviewCharts($startTime: Int!, $skip: Int!) {
                pancakeDayDatas(first: 1000, skip: $skip, where: { date_gt: $startTime }, orderBy: date, orderDirection: asc) {
                    date
                    dailyVolumeUSD
                    totalLiquidityUSD
                }
            }`;
        const variables = {
            startTime: 1610000000,
            skip: 0
        };
        infoClient.request(query, variables).then(data => setTotalVolume(data.pancakeDayDatas.reduce((acc, item) => (+item.dailyVolumeUSD) + acc, 0)))
    }, []);

    useEffect(() => {
        const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org");
        provider.getBlockNumber().then((blockNumber) => {
            setCurrentBlock(blockNumber);
        });
    }, []);

    useEffect(() => {
        if ( !currentBlock ) return;
        const infoClient = new GraphQLClient('https://api.thegraph.com/subgraphs/name/swychfinance/exchange')
        const block = currentBlock - (86400 / 3);
        const query = `query overview {
                pancakeFactories(
                ${block ? `block: { number: ${block}}` : ``}
                first: 1) {
                    totalTransactions
                    totalVolumeUSD
                    totalLiquidityUSD
                }
            }`;
        infoClient.request(query).then(data => setVolume24(data.pancakeFactories[0].totalVolumeUSD))
    }, [currentBlock]);

    useEffect(() => {
        if ( volume24 && totalVolume ) {
            setVolume(totalVolume - volume24);
        }
    }, [volume24, totalVolume]);

    const combinedItemRefs = (el) => {
        if(el && !itemRef.current.includes(el)) {
            itemRef.current.push(el);
        }
    }

    useEffect(() => {
        const isVisibleTrigger = ScrollTrigger.isInViewport(triggerRef.current);

        if(isVisibleTrigger) {
            if(!isLoading) tlShow(itemRef.current);
        } else {
            magicShow(triggerRef.current, itemRef.current);
        }
    }, [isLoading]);

    const items = advantage.items.map((item, index) => {
        const Icon = icons[index];

        return (
            <div className="sw-advantage-item" ref={combinedItemRefs} key={index}>
                <div className="sw-advantage-icon">
                    {<Icon/>}
                </div>
                <div className="sw-advantage-text">
                    <div className="sw-advantage-title">
                        {item.title}
                    </div>
                    <div className="sw-advantage-caption">
                        {item.caption}
                    </div>
                </div>
            </div>
        );
    });

    return (
        <section className="sw-advantage" ref={triggerRef}>
            <div className="sw-advantage-content">
                <div className="sw-advantage-container">
                    <div className="sw-advantage-items">
                        <div className="sw-advantage-item">
                            <div className="sw-advantage-icon">
                                {<Stat/>}
                            </div>
                            <div className="sw-advantage-text">
                                <div className="sw-advantage-title">
                                    Volume 24h
                                </div>
                                <div className="sw-advantage-caption">$
                                    {(+volume/1000).toFixed(2)}K
                                </div>
                            </div>
                        </div>
                        <div className="sw-advantage-item">
                            <div className="sw-advantage-icon">
                                {<ArrowRightUpT/>}
                            </div>
                            <div className="sw-advantage-text">
                                <div className="sw-advantage-title">
                                    Total Volume
                                </div>
                                <div className="sw-advantage-caption">$
                                    {(+totalVolume/1000000).toFixed(2)}M
                                </div>
                            </div>
                        </div>
                        {items}
                        <div className="sw-advantage-item">
                            <div className="sw-advantage-icon">
                                {<Chart/>}
                            </div>
                            <div className="sw-advantage-text">
                                <div className="sw-advantage-title">
                                    Total Value Locked
                                </div>
                                <div className="sw-advantage-caption">$
                                    {(+liquidity/1000000).toFixed(2)}M
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
