import {LocalesContextProvider} from "../providers/LocalesContextProvider";
import Layout from "../components/Layout";
import Background from "../components/Background";
import Navbar from "../components/Navbar";
import Sections from "../sections";
import Footer from "../components/Footer";
import './index.scss';

const App = () => {
    return (
        <LocalesContextProvider>
            <Layout>
                <Navbar/>
                <Background>
                    <Sections/>
                    <Footer/>
                </Background>
            </Layout>
        </LocalesContextProvider>
    );
}

export default App;
