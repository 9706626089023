/*!
 * SmoothScrollbar Disable Plugin
 *
 * @version 1.1.0
 * @author Artem Dordzhiev (Draft)
 */

import Scrollbar from "smooth-scrollbar";

export default class DisablePlugin extends Scrollbar.ScrollbarPlugin {
    static pluginName = 'Disable';
    static defaultOptions = {
        disable: false
    };

    transformDelta(delta, fromEvent) {
        return this.options.disable ? {x: 0, y: 0} : delta;
    }
}
