import {useContext} from "react";
import {LocalesContext} from "../../context";
import {Social} from "../Social";
import {SocialItem} from "../Social/SocialItem";
import {
    Twitter,
    Discord
} from "../Icon";

const Footer = () => {
    const {data} = useContext(LocalesContext);
    const { footer, social } = data;
    const socialIcons = [Twitter, Discord];

    const cols = footer.cols.map((col, index) => (
        <div className="sw-footer-ngrid-col" key={index}>
            <div className="sw-footer-title">{col.title}</div>
            <div className="sw-footer-navs">
                {
                    col.navs.map((nav, index) => (
                        <div className="sw-footer-nav" key={index}>
                            <a href={nav.link}>
                                <span>{nav.title}</span>
                            </a>
                        </div>
                    ))
                }
            </div>
        </div>
    ));

    const socialItems = social.items.map((item, index) => {
        const Icon = socialIcons[index];

        return (
            <SocialItem
                name={item.name}
                link={item.link}
                icon={<Icon/>}
                key={index}
            />
        )
    });

    return (
        <div className="sw-footer">
            <div className="sw-footer-content">
                <div className="sw-footer-container">
                    <div className="sw-footer-row">
                        <div className="sw-footer-grid">
                            <a className="sw-footer-logo" href="/">
                                <img src={"/img/logo-footer.svg"} alt="Logo"/>
                            </a>
                            <div className="sw-footer-social">
                                <Social>
                                    {socialItems}
                                </Social>
                            </div>
                            <div className="sw-footer-ngrid">
                                {cols}
                            </div>
                        </div>
                    </div>
                    <div className="sw-footer-row">
                        <div className="sw-footer-copyright"><span>{footer.copyright}</span></div>
                        <div className="sw-footer-copyright">
                            <a href="//cuberto.com" target="_blank" rel="noreferrer" aria-label="Cuberto">{footer.by}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
