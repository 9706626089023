import {forwardRef} from "react";
import {ArrowRightUp} from "../../components/Icon";

export const ButtonCta = forwardRef(({text, link}, ref) => {
    return (
        <a className="sw-btn sw-btn_cta" href={ link || "/"} ref={ref}>
            <span>{text}</span>
            <span className="sw-btn_cta-icon">
                <ArrowRightUp/>
            </span>
        </a>
    )
});
