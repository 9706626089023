import {useEffect, useState} from "react";
import {LocalesContext} from "../../context";

export const LocalesContextProvider = ({children}) => {
    const [data, setData] = useState();

    const fetchData = async () => {
        const res = await fetch("/locales/en/translation.json");
        const json = await res.json();
        setData(json);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <LocalesContext.Provider value={{data}}>
            {data && children}
        </LocalesContext.Provider>
    );
};
