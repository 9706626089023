import {useContext, useState, useRef} from "react";
import {ButtonNav, ButtonMenu} from '../Button';
import {LayoutContext, LocalesContext} from "../../context";

const Navbar = () => {
    const {data} = useContext(LocalesContext);
    const {scrollbar} = useContext(LayoutContext);
    const [isOpen, setIsOpen] = useState(false);
    const stripRef = useRef();

    const handleClick = () => {
        setIsOpen(current => !current);

        if(!isOpen) {
            document.body.classList.add("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', {disable: true});
        } else {
            document.body.classList.remove("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', {disable: false});
        }
    };

    return (
        <nav className={`sw-navbar ${isOpen ? "-open" : ""}`}>
            <div className="sw-navbar-fill"></div>
            <div className="sw-navbar-strip" ref={stripRef}>
                <div className="sw-navbar-container -lg">
                    <div className="sw-navbar-grid">
                        <div className="sw-navbar-grid-col -left">
                            <a className="sw-navbar-logo" href="/" aria-label="Home">
                                <img src={"/img/logo.svg"} alt=""/>
                            </a>
                        </div>
                        <div className="sw-navbar-grid-col -center">
                            <div className="sw-navbar-navs">
                                {
                                    data.navbar.navs.map((nav, index) => (
                                        <a className="sw-navbar-nav" href={nav.link} key={index}>
                                            <span>{nav.name}</span>
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="sw-navbar-grid-col -right">
                            <div className="sw-navbar-action">
                                <ButtonNav
                                    text="Open Dapp"
                                    link="https://app.swych.finance"
                                />
                            </div>
                            <div className="sw-navbar-toggle">
                                <ButtonMenu onClick={handleClick}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
