import {useLayoutEffect, useRef, useState} from 'react';
import SmoothScrollbar from "smooth-scrollbar";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import DisablePlugin from "../../vendor/smooth-scrollbar/DisablePlugin";
import SoftScrollPlugin from "../../vendor/smooth-scrollbar/SoftScrollPlugin";
import {LayoutContext} from "../../context";
import {Loader} from "../Loader";

gsap.registerPlugin(ScrollTrigger);
SmoothScrollbar.use(DisablePlugin, SoftScrollPlugin);

const Layout = ({children}) => {
    const [isLoading, setIsLoading] = useState(true);
    const layoutRef = useRef();
    const Scrollbar = useRef();

    const initSmoothScrolling = () => {
        if (!window.matchMedia('(pointer:fine)').matches) return;

        Scrollbar.current = SmoothScrollbar.init(layoutRef.current, {
            continuousScrolling: false,
            renderByPixels: true,
            damping: 0.12
        });
        document.documentElement.classList.add('smooth');
    }

    const initScrollTrigger = () => {
        ScrollTrigger.scrollerProxy(Scrollbar.current.containerEl, {
            scrollTop(value) {
                if (arguments.length) Scrollbar.current.scrollTop = value;
                return Scrollbar.current.scrollTop;
            }
        });
        ScrollTrigger.defaults({scroller: Scrollbar.current.containerEl});
        Scrollbar.current.addListener(ScrollTrigger.update);
    }

    const scrollTop = () => {
        return Scrollbar.current ? Scrollbar.current.scrollTop : window.pageYOffset;
    }

    useLayoutEffect(() => {
        initSmoothScrolling();
        if(Scrollbar.current) initScrollTrigger();

        setTimeout(() => {
            setIsLoading(false);
        }, 700);

        return () => {
            if (ScrollTrigger) {
                ScrollTrigger.getAll().forEach((st) => st.kill(false));
                ScrollTrigger.clearScrollMemory();
            }
        }
    }, []);

    return (
        <LayoutContext.Provider value={{
            isLoading,
            scrollbar: Scrollbar,
            scrollTop
        }}>
            <Loader loading={isLoading}/>
            <div className="sw-layout" ref={layoutRef}>
                {children}
            </div>
        </LayoutContext.Provider>
    );
};

export default Layout;
