import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {textAppear} from "../../../shared/transition";

export const magicShow = (el, tweens) => {
    ScrollTrigger.create({
        animation: tlShow(tweens),
        trigger: el,
        start: 'top bottom'
    });
}

export const tlShow = (tweens) => {
    const tl = gsap.timeline();

    tl.add(textAppear(tweens), 0);

    return tl;
}
