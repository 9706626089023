import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export const magicShow = (trigger, item) => {
    ScrollTrigger.create({
        animation: tlShow(item),
        trigger,
        start: 'top bottom'
    });
}

export const tlShow = (item) => {
    const tl = gsap.timeline();
    const wc = [item];

    tl.set(wc, {willChange: "transform"});

    tl.fromTo(wc, {
        y: 70,
        opacity: 0,
    },{
        y: 0,
        opacity: 1,
        duration: 2,
        stagger: 0.07,
        ease: "expo.out"
    }, 0);

    tl.set(wc, {willChange: "auto"});

    return tl;
}
