import {useContext, useEffect, useRef} from "react";
import gsap from "gsap";
import {Reeller, ScrollerPlugin} from 'reeller';
import {LayoutContext, LocalesContext} from "../../context";
import {magicShow} from "./transitions";

Reeller.registerGSAP(gsap);
Reeller.use(ScrollerPlugin);

export const BrandSection = () => {
    const {data} = useContext(LocalesContext);
    const {brand} = data;
    const {scrollTop} = useContext(LayoutContext);
    const triggerRef = useRef();
    const headerRef = useRef();
    const reelRef = useRef([]);
    reelRef.current = [];

    const combinedItemRefs = (el) => {
        if(el && !reelRef.current.includes(el)) {
            reelRef.current.push(el);
        }
    }

    useEffect(() => {
        magicShow(triggerRef.current, headerRef.current);
    }, []);

    useEffect(() => {
        reelRef.current.forEach((reel, index) => {
            new Reeller({
                container: reel,
                wrapper: ".sw-brand-reel-items",
                itemSelector: ".sw-brand-reel-item",
                speed: 25,
                plugins: {
                    scroller: {
                        multiplier: 0.1,
                        speed: 1,
                        threshold: 1,
                        reversed: !!(index % 2),
                        scrollProxy: () => scrollTop()
                    },
                },
            });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="sw-brand" ref={triggerRef}>
            <div className="sw-brand-content">
                <div className="sw-brand-header">
                    <h2 ref={headerRef}>
                        {brand.header}
                    </h2>
                </div>
                <div className="sw-brand-reels">
                    <div className="sw-brand-reel" ref={combinedItemRefs}>
                        <div className="sw-brand-reel-items">
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/1.png"} srcSet={"/img/home/brand/1@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/2.png"} srcSet={"/img/home/brand/2@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/3.png"} srcSet={"/img/home/brand/3@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/4.png"} srcSet={"/img/home/brand/4@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/5.png"} srcSet={"/img/home/brand/5@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/6.png"} srcSet={"/img/home/brand/6@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/7.png"} srcSet={"/img/home/brand/7@2x.png 2x"} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sw-brand-reel" ref={combinedItemRefs}>
                        <div className="sw-brand-reel-items">
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/7.png"} srcSet={"/img/home/brand/7@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/6.png"} srcSet={"/img/home/brand/6@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/5.png"} srcSet={"/img/home/brand/5@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/4.png"} srcSet={"/img/home/brand/4@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/3.png"} srcSet={"/img/home/brand/3@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/2.png"} srcSet={"/img/home/brand/2@2x.png 2x"} alt=""/>
                            </div>
                            <div className="sw-brand-reel-item">
                                <img src={"/img/home/brand/1.png"} srcSet={"/img/home/brand/1@2x.png 2x"} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
