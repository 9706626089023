import {useContext, useEffect, useRef} from "react";
import {LocalesContext} from "../../context";
import {ButtonCta} from "../../components/Button";
import {Speaker} from "../../components/Icon";
import {magicShow, magicItemShow} from "./transitions";

export const FeatureSection = () => {
    const {data} = useContext(LocalesContext);
    const {feature} = data;
    const length = feature.items.length;
    const triggerRef = useRef();
    const captionRef = useRef();
    const headerRef = useRef();
    const itemRef = useRef([]);
    itemRef.current = [];

    const combinedItemRefs = (el) => {
        if(el && !itemRef.current.includes(el)) {
            itemRef.current.push(el);
        }
    }

    useEffect(() => {
        magicItemShow(itemRef.current);
        magicShow(triggerRef.current, [captionRef.current, headerRef.current]);
    }, []);

    const items = feature.items.map((item, index) => (
        <div className="sw-feature-item" ref={combinedItemRefs} key={index}>
            {index === length - 1 && (
                <div className="sw-feature-icon">
                    <Speaker/>
                </div>
            )}
            <div className="sw-feature-title">{item.title}</div>
            {item.subtitle && <div className="sw-feature-subtitle">{item.subtitle}</div>}
            {item.text && <div className="sw-feature-text">{item.text}</div>}
            {index !== length - 1 && (
                <div className="sw-feature-img">
                    <img
                        src={`/img/home/feature/${index + 1}.png`}
                        srcSet={`/img/home/feature/${index + 1}@2x.png 2x`}
                        alt=""
                    />
                </div>
            )}
            <div className="sw-feature-action">
                <ButtonCta text="Learn more" link={item.link}/>
            </div>
        </div>
    ));

    return (
        <section className="sw-feature" ref={triggerRef}>
            <div className="sw-feature-content">
                <div className="sw-feature-container">
                    <div className="sw-feature-caption">
                        <span ref={captionRef}>{feature.caption}</span>
                    </div>
                    <div className="sw-feature-header">
                        <h2 ref={headerRef}>
                            {feature.header}
                        </h2>
                    </div>
                    <div className="sw-feature-items">
                        {items}
                    </div>
                </div>
            </div>
        </section>
    )
}
